import { FormEventHandler, FunctionComponent, PropsWithChildren } from 'react';
import * as FormPrimitive from '@radix-ui/react-form';
import { Flex, Spinner, Text } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { PrimaryButton, SecondaryButton } from '../Buttons';

const useStyles = tss.withName('FormPanel').create(() => ({
  container: {
    backgroundColor: 'var(--white)',
    minWidth: '360px',
    padding: '24px',
    margin: '0 auto',
    border: '1px solid var(--slate-200)',
    borderRadius: '6px',
    boxShadow: '0px 1px 2px -1px #0000001A, 0px 1px 3px 0px #0000001A'
  },
  heading: {
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 700,
    color: 'var(--font-primary-color)'
  }
}));

type FormPanelProps = PropsWithChildren & {
  className?: string;
  heading?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onCancelClick: () => void;
};

export const FormPanel: FunctionComponent<FormPanelProps> = ({
  className,
  onSubmit,
  onCancelClick,
  isSubmitting,
  isDisabled,
  heading,
  children
}) => {
  const { classes } = useStyles();

  return (
    <Flex direction='column' gap='4' className={clsx(classes.container, className)}>
      {heading && (
        <Text data-testid='form-panel-heading' className={classes.heading}>
          {heading}
        </Text>
      )}
      <FormPrimitive.Root onSubmit={onSubmit}>
        <Flex direction='column' gap='4'>
          <Flex direction='column' gap='4'>
            {children}
          </Flex>
          <Flex direction='row' gap='4' align='center' justify='end'>
            {isSubmitting && <Spinner />}
            <SecondaryButton
              type='button'
              onClick={onCancelClick}
              disabled={isSubmitting}
              data-testid='form-panel-cancel-btn'
            >
              Cancel
            </SecondaryButton>
            <FormPrimitive.Submit
              asChild
              disabled={isSubmitting || isDisabled}
              data-testid='form-panel-submit-btn'
            >
              <PrimaryButton>Save</PrimaryButton>
            </FormPrimitive.Submit>
          </Flex>
        </Flex>
      </FormPrimitive.Root>
    </Flex>
  );
};
