export enum LICENCES {
  E3 = 'E3',
  E5 = 'E5',
  BUSINESS_PREMIUM = 'BUSINESS_PREMIUM'
}
export enum LICENCES_LABELS {
  E3 = 'E3',
  E5 = 'E5',
  BUSINESS_PREMIUM = 'Business Premium'
}

export enum LICENCES_SHORT_LABELS {
  E3 = 'E3',
  E5 = 'E5',
  BUSINESS_PREMIUM = 'BP'
}

export type SortOrder = 'asc' | 'desc';
export type SortBy = 'createdAt' | 'm365Licence' | 'enabled' | 'name';

/**
 * Used in Create/Update Forms
 */
export const LICENCE_OPTIONS = [
  { label: LICENCES_LABELS.E3, value: LICENCES.E3 },
  { label: LICENCES_LABELS.E5, value: LICENCES.E5 },
  { label: LICENCES_LABELS.BUSINESS_PREMIUM, value: LICENCES.BUSINESS_PREMIUM }
];
