import { FunctionComponent, useMemo } from 'react';
import { Grid } from '@radix-ui/themes';

import { DASHBOARD_STATUS } from 'app/constants/dashboard';
import { DashboardControl } from 'app/types';

import { ActionCard } from './ActionCard';
import { ActionCardLink } from './ActionCardLink';

type SolutionsProps = {
  dashboardControls: DashboardControl[];
};

export const ActionCards: FunctionComponent<SolutionsProps> = ({ dashboardControls }) => {
  const filterByStatus = (controls: DashboardControl[], status: DASHBOARD_STATUS) =>
    controls.filter(control => control.status === status);

  const filteredData = useMemo(() => {
    const incompleteCards = dashboardControls.filter(
      control =>
        control.status === DASHBOARD_STATUS.IN_PROGRESS ||
        control.status === DASHBOARD_STATUS.NOT_STARTED
    );
    const highRisk = incompleteCards.filter(control => control.securityRisk === 'HIGH');
    const quickWins = highRisk.filter(control => control.businessRisk === 'LOW');

    return {
      totalIncomplete: incompleteCards.length,
      numIncompleteInProgress: filterByStatus(incompleteCards, DASHBOARD_STATUS.IN_PROGRESS).length,
      numIncompleteNotStarted: filterByStatus(incompleteCards, DASHBOARD_STATUS.NOT_STARTED).length,

      totalHighRisk: highRisk.length,
      numHighRiskInProgress: filterByStatus(highRisk, DASHBOARD_STATUS.IN_PROGRESS).length,
      numHighRiskNotStarted: filterByStatus(highRisk, DASHBOARD_STATUS.NOT_STARTED).length,

      totalQuickWins: quickWins.length,
      numQuickWinsInProgress: filterByStatus(quickWins, DASHBOARD_STATUS.IN_PROGRESS).length,
      numQuickWinsNotStarted: filterByStatus(quickWins, DASHBOARD_STATUS.NOT_STARTED).length
    };
  }, [dashboardControls]);

  return (
    <Grid gap='4' data-testid='action-cards' columns={'repeat(auto-fill, minmax(333px, 1fr))'}>
      <ActionCard
        variant={'quickWins'}
        cardLength={filteredData.totalQuickWins}
        data-testid='quick-wins'
      >
        <ActionCardLink status='IN_PROGRESS' actionLength={filteredData.numQuickWinsInProgress} />

        <ActionCardLink status='NOT_STARTED' actionLength={filteredData.numQuickWinsNotStarted} />
      </ActionCard>

      <ActionCard
        variant={'highRisk'}
        cardLength={filteredData.totalHighRisk}
        data-testid='high-risk'
      >
        <ActionCardLink status='IN_PROGRESS' actionLength={filteredData.numHighRiskInProgress} />

        <ActionCardLink status='NOT_STARTED' actionLength={filteredData.numHighRiskNotStarted} />
      </ActionCard>
      <ActionCard
        variant={'incomplete'}
        cardLength={filteredData.totalIncomplete}
        data-testid='incomplete'
      >
        <ActionCardLink status='IN_PROGRESS' actionLength={filteredData.numIncompleteInProgress} />

        <ActionCardLink status='NOT_STARTED' actionLength={filteredData.numIncompleteNotStarted} />
      </ActionCard>
    </Grid>
  );
};
