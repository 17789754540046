import { FunctionComponent } from 'react';
import * as FormPrimitive from '@radix-ui/react-form';
import { tss } from 'tss-react';

const useStyles = tss.withName('FormField').create(() => ({
  field: {}
}));

export const FormField: FunctionComponent<FormPrimitive.FormFieldProps> = ({
  children,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <FormPrimitive.Field {...props} className={classes.field}>
      {children}
    </FormPrimitive.Field>
  );
};
