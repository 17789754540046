import { FunctionComponent, useEffect, useState } from 'react';
import { Flex, Select, Spinner } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { SolutionIcon } from 'app/components';
import { useFetchSolutions } from 'app/queries/useSolutionQueries';
import { Solution } from 'app/types';

const useStyles = tss.withName('SolutionFilter').create(() => ({
  trigger: {
    padding: 'var(--space-2) var(--space-3)',
    backgroundColor: 'var(--white)',
    cursor: 'pointer',
    color: 'var(--font-primary-color)',
    height: '40px',

    '&:hover': {
      backgroundColor: 'var(--accent-a3)'
    },

    '&:where(:disabled)': {
      cursor: 'not-allowed'
    },

    '&>*': {
      height: '20px'
    }
  },

  triggerIcon: {
    flex: 'none'
  },

  item: {
    '&>svg': {
      height: '16px',
      width: '16px'
    }
  }
}));

type SolutionFilterProps = {
  defaultValue?: string;
  onChange: (value: string) => void;
};
export const SolutionFilter: FunctionComponent<SolutionFilterProps> = ({
  defaultValue = 'all',
  onChange
}) => {
  const { classes } = useStyles();
  const [selectedSolution, setSelectedSolution] = useState<Solution | undefined>(undefined);

  const { data, isFetching } = useFetchSolutions(true);

  const handleSolutionChange = (value: string) => {
    setSelectedSolution((data || []).find(({ id }) => `${id}` === value));
    onChange(value);
  };

  useEffect(() => {
    setSelectedSolution((data || []).find(({ id }) => `${id}` === defaultValue));
  }, [data, defaultValue]);

  return (
    <Select.Root
      disabled={isFetching}
      size='2'
      defaultValue={defaultValue}
      onValueChange={handleSolutionChange}
    >
      <Select.Trigger
        color='gray'
        variant='soft'
        className={classes.trigger}
        data-testid='solution-filter-trigger'
      >
        <Flex as='span' align='center' gap='2'>
          {isFetching && <Spinner />}
          {!selectedSolution && 'All Solutions'}
          {selectedSolution && (
            <>
              <SolutionIcon className={classes.triggerIcon} solution={selectedSolution} />
              {selectedSolution.name}
            </>
          )}
        </Flex>
      </Select.Trigger>
      <Select.Content color='gray' variant='soft' data-testid='solution-filter-content'>
        <Select.Item value='all'>All Solutions</Select.Item>
        {(data || []).map(solution => (
          <Select.Item key={solution.id} className={classes.item} value={`${solution.id}`}>
            <Flex as='span' align='center' gap='2'>
              <SolutionIcon solution={solution} />
              {solution.name}
            </Flex>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};
