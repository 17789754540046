import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { axiosInstance } from 'app/constants/axios';
import { USERS_API_URLS } from 'app/constants/routes';
import { ApiError, CreateUserRequest, PagedResponse, UpdateUserRequest, User } from 'app/types';

type UseFetchUsersOptions = {
  page?: number;
  size?: number;
  sort?: string;
  freeText?: string;
  enabled?: boolean;
};

export const USE_FETCH_USER_LIST_QUERY_KEY = 'useFetchUserList';
export const useFetchUserList = ({
  page = 0,
  size = 20,
  sort = 'name,desc',
  freeText = '',
  enabled = true
}: UseFetchUsersOptions) => {
  return useQuery({
    queryKey: [USE_FETCH_USER_LIST_QUERY_KEY, { page, size, sort, freeText }],
    queryFn: async () => {
      const response = await axiosInstance.get<PagedResponse<User>>(USERS_API_URLS.ROOT, {
        params: { page, size, sort, freeText }
      });
      return response.data;
    },
    enabled
  });
};

const USE_FETCH_USER_QUERY_KEY = 'useFetchUser';
export const useFetchUser = (enabled = false, id: string) =>
  useQuery({
    queryKey: [USE_FETCH_USER_QUERY_KEY, id],
    queryFn: async () =>
      await axiosInstance
        .get<User>(USERS_API_URLS.SINGLE.replace(':userId', id))
        .then(response => response.data),
    enabled
  });

export interface CreateUserMutationOptions {
  user: CreateUserRequest;
}
export const useCreateUserMutation = () =>
  useMutation<User, Error | ApiError, CreateUserMutationOptions>({
    mutationKey: ['useCreateUserMutation'],
    mutationFn: async ({ user }: CreateUserMutationOptions) =>
      await axiosInstance
        .post<User>(USERS_API_URLS.ROOT, user)
        .then(response => response.data)
        .catch((err: Error | AxiosError<ApiError>) => {
          if (axios.isAxiosError(err)) {
            throw err.response?.data;
          } else {
            throw err;
          }
        })
  });

export interface UpdateUserMutationOptions {
  userId: number;
  user: UpdateUserRequest;
}
export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<User, Error | ApiError, UpdateUserMutationOptions>({
    mutationKey: ['useUpdateUserMutation'],
    mutationFn: async ({ userId, user }: UpdateUserMutationOptions) =>
      await axiosInstance
        .put<User>(USERS_API_URLS.SINGLE.replace(':userId', `${userId}`), user)
        .then(response => response.data)
        .catch((err: Error | AxiosError<ApiError>) => {
          if (axios.isAxiosError(err)) {
            throw err.response?.data;
          } else {
            throw err;
          }
        }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [USE_FETCH_USER_LIST_QUERY_KEY, USE_FETCH_USER_QUERY_KEY]
      })
  });
};

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['useDeleteUser'],
    mutationFn: async (userId: number) =>
      await axiosInstance
        .delete(USERS_API_URLS.SINGLE.replace(':userId', `${userId}`))
        .then(response => response.data)
        .catch((err: Error | AxiosError<ApiError>) => {
          if (axios.isAxiosError(err)) {
            throw err.response?.data;
          } else {
            throw err;
          }
        }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [USE_FETCH_USER_LIST_QUERY_KEY] })
  });
};
