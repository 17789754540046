import { FunctionComponent } from 'react';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Button } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('ColumnSortButton').create(() => ({
  sortButton: {
    color: 'var(--font-primary-color)',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 1.5,
    border: 0,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: 'transparent',
    cursor: 'pointer',
    width: '100%',
    textTransform: 'capitalize',
    justifyContent: 'flex-start',
    padding: 0,
    margin: 0,

    '&:hover, &:focus': {
      backgroundColor: 'var(--bg-default)'
    }
  }
}));

interface UserColumnButtonProps {
  label: string;
  value: string;
  handleSort: (value: string) => void;
  sortState: {
    sortBy: string;
    sortOrder: string;
  };
}

export const ColumnSortButton: FunctionComponent<UserColumnButtonProps> = ({
  label,
  value,
  sortState,
  handleSort
}) => {
  const { classes } = useStyles();
  return (
    <Button className={classes.sortButton} onClick={() => handleSort(value)}>
      {label}
      {sortState.sortBy === value &&
        (sortState.sortOrder === 'asc' ? <CaretUp weight='fill' /> : <CaretDown weight='fill' />)}
    </Button>
  );
};
