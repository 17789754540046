import { FunctionComponent, useMemo, useState } from 'react';
import { Warning } from '@phosphor-icons/react';
import { Button, Callout, Flex, Spinner, Text } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { ControlStatusIcon } from 'app/components/Controls';
import {
  CONTROL_STATUS,
  CONTROL_STATUS_LABELS,
  CONTROL_STATUS_VALUES
} from 'app/constants/controls';
import { UPDATE_STATUS_REQUIRED_ROLES } from 'app/constants/rbac';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useStatusMutation } from 'app/queries/useControlQueries';
import { CustomerControl } from 'app/types';

const useStyles = tss.withName('StatusSection').create(() => ({
  container: {
    padding: '16px 0',
    borderBottom: '1px solid var(--slate-200)'
  },
  label: {
    color: 'var(--font-primary-color)',
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 700
  },
  current: {
    color: 'var(--slate-900)',
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 400
  },
  statusGroup: {
    backgroundColor: 'var(--bg-default)',
    padding: '5px',
    borderRadius: '6px'
  },
  button: {
    padding: '6px 8px',
    margin: 0,
    cursor: 'not-allowed'
  },
  isAllowedEdit: {
    cursor: 'pointer',

    '&[data-disabled=true]': {
      cursor: 'not-allowed'
    }
  },
  buttonActive: {
    backgroundColor: 'var(--white)'
  }
}));

type StatusSectionProps = {
  control: CustomerControl;
  onControlChange: (control: CustomerControl) => void;
};

export const StatusSection: FunctionComponent<StatusSectionProps> = ({
  control,
  onControlChange
}) => {
  const { classes } = useStyles();
  const { isAllowed } = useLoggedInUser();

  const [selectedStatus, setSelectedStatus] = useState(
    control.status || CONTROL_STATUS.NOT_STARTED
  );

  const { mutate, isPending, isError } = useStatusMutation();

  const userIsAllowed = useMemo(() => isAllowed(UPDATE_STATUS_REQUIRED_ROLES), [isAllowed]);

  const handleClick = (newStatus: CONTROL_STATUS) => {
    const oldStatus = selectedStatus;

    if (userIsAllowed) {
      mutate(
        { externalControlId: control.externalControlId, status: newStatus },
        {
          onSuccess: response => {
            setSelectedStatus(newStatus);
            onControlChange(response);
          },
          onError: () => setSelectedStatus(oldStatus)
        }
      );
    }
  };

  return (
    <Flex gap='2' direction='column' className={classes.container} data-testid='side-panel-section'>
      <Flex gap='2' justify='between' flexGrow='1' align='center'>
        <Flex gap='2' align='center'>
          <Text as='span' className={classes.label}>
            Status
          </Text>
          {isPending && <Spinner />}
        </Flex>
        <Text as='span' className={classes.current}>
          {CONTROL_STATUS_LABELS[selectedStatus]}
        </Text>
      </Flex>

      <Flex gap='2' align='center' justify='between' className={classes.statusGroup}>
        {CONTROL_STATUS_VALUES.map(status => (
          <Button
            key={status}
            className={clsx(
              classes.button,
              userIsAllowed && classes.isAllowedEdit,
              status === selectedStatus && classes.buttonActive
            )}
            variant='ghost'
            color='gray'
            disabled={isPending}
            onClick={() => handleClick(status)}
            data-testid={`status-btn-${status}`}
          >
            <ControlStatusIcon status={status as CONTROL_STATUS} size={16} />
          </Button>
        ))}
      </Flex>
      {isError && (
        <Callout.Root color='red' size='1' data-testid='status-error'>
          <Callout.Icon>
            <Warning />
          </Callout.Icon>
          <Callout.Text>Status update failed. Please try again.</Callout.Text>
        </Callout.Root>
      )}
    </Flex>
  );
};
