import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { axiosInstance } from 'app/constants/axios';
import { MASTER_CONTROLS_API_URLS } from 'app/constants/routes';
import { ApiError, Control, PagedResponse } from 'app/types';

type UseFetchMasterControlsOptions = {
  page?: number;
  size?: number;
  sort?: string;
  freeText?: string;
  enabled?: boolean;
};

export const USE_FETCH_MASTER_CONTROLS_LIST_QUERY_KEY = 'useFetchMasterControlsList';

export const useFetchMasterControlsList = ({
  page = 0,
  size = 20,
  sort = 'name,desc',
  freeText = '',
  enabled = true
}: UseFetchMasterControlsOptions) => {
  return useQuery({
    queryKey: [USE_FETCH_MASTER_CONTROLS_LIST_QUERY_KEY, { page, size, sort, freeText }],
    queryFn: async () => {
      const response = await axiosInstance.get<PagedResponse<Control>>(
        MASTER_CONTROLS_API_URLS.ROOT,
        {
          params: { page, size, sort, freeText }
        }
      );
      return response.data;
    },
    enabled
  });
};

export const useDeleteMasterControlMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['useDeleteMasterControlMutation'],
    mutationFn: async (externalControlId: number) =>
      await axiosInstance
        .delete(
          MASTER_CONTROLS_API_URLS.SINGLE.replace(':externalControlId', `${externalControlId}`)
        )
        .then(response => response.data)
        .catch((err: Error | AxiosError<ApiError>) => {
          if (axios.isAxiosError(err)) {
            throw err.response?.data;
          } else {
            throw err;
          }
        }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [USE_FETCH_MASTER_CONTROLS_LIST_QUERY_KEY] })
  });
};
