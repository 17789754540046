import { FunctionComponent } from 'react';
import { Button, ButtonProps } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

const useStyles = tss.withName('SecondaryButton').create(() => ({
  button: {
    padding: '0 16px',
    margin: 0,
    height: '40px',
    color: 'var(--slate-700)',
    fontSize: '14px',
    fontWeight: 700,
    cursor: 'pointer',

    '&[data-disabled="true"]': {
      cursor: 'not-allowed'
    }
  }
}));

export const SecondaryButton: FunctionComponent<
  ButtonProps & React.RefAttributes<HTMLButtonElement>
> = ({ className, children, ...props }) => {
  const { classes } = useStyles();

  return (
    <Button
      size='3'
      color='gray'
      variant='ghost'
      className={clsx(classes.button, className)}
      {...props}
    >
      {children}
    </Button>
  );
};
