import { FunctionComponent, useMemo } from 'react';
import { Box, Text } from '@radix-ui/themes';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { tss } from 'tss-react';

import { DashboardControl, RangeType } from 'app/types/dashboard';

import {
  getRangeStartDate,
  groupedAndSortedControls,
  tooltipDateFormatter
} from '../dashboard.utils';

import { ProgressReportTooltip } from './ProgressReportTooltip';

const useStyles = tss.withName('ProgressReportChart').create(() => ({
  container: {
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chartContainer: {
    flex: '1'
  },
  noDataText: {
    color: 'var(--font-muted-color)',
    fontSize: '16px'
  }
}));

type ProgressReportChartProps = {
  dashboardControls: DashboardControl[];
  selectedRange: RangeType;
};

export const ProgressReportChart: FunctionComponent<ProgressReportChartProps> = ({
  dashboardControls,
  selectedRange
}) => {
  const { classes } = useStyles();

  const rangeStartDate = useMemo(() => getRangeStartDate(selectedRange), [selectedRange]);

  const data = useMemo(
    () =>
      groupedAndSortedControls(dashboardControls.filter(control => control.status === 'COMPLETED'))
        .filter(([date]) => new Date(date) > rangeStartDate)
        .reduce((acc: { date: Date; total: number; current: number }[], [date, controls]) => {
          const prevVal = acc.length > 0 ? acc[acc.length - 1].total : 0;
          acc.push({
            date: new Date(date),
            total: prevVal + controls.length,
            current: controls.length
          });
          return acc;
        }, []),
    [dashboardControls, rangeStartDate]
  );

  return (
    <Box className={classes.container}>
      {data.length ? (
        <Box className={classes.container} data-testid={'progress-report-chart'}>
          <ResponsiveContainer width='100%' height={176}>
            <LineChart data={data}>
              <XAxis
                dataKey='date'
                tickFormatter={tooltipDateFormatter}
                domain={['dataMax', 'dataMin']}
                tick={{ fill: 'var(--font-muted-color)', fontSize: '12px' }}
                interval={'preserveStartEnd'}
              />
              <Tooltip content={<ProgressReportTooltip />} />
              <CartesianGrid stroke='var(--slate-200)' vertical={false} />
              <Line type='linear' dataKey='total' stroke='#0088CD' dot={false} strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      ) : (
        <Text className={classes.noDataText} data-testid='progress-report-no-data'>
          No data available
        </Text>
      )}
    </Box>
  );
};
