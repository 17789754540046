import { FunctionComponent } from 'react';
import { Flex, Text } from '@radix-ui/themes';
import { formatDistanceToNowStrict, lightFormat } from 'date-fns';
import { tss } from 'tss-react';

import { UserAvatar } from 'app/components';
import { ControlNote } from 'app/types/controls';

const useStyles = tss.withName('Note').create(() => ({
  avatar: {
    height: '28px',
    width: '28px'
  },
  details: {
    fontSize: '14px',
    lineHeight: 1.4,
    fontWeight: '700',
    justifyContent: 'space-between'
  },
  date: {
    color: 'var(--font-secondary-color)',
    textAlign: 'right'
  },
  note: {
    fontSize: '14px',
    lineHeight: 1.7,
    fontWeight: '400',
    whiteSpace: 'pre-wrap'
  }
}));

type NotesListProps = {
  note: ControlNote;
};

export const Note: FunctionComponent<NotesListProps> = ({
  note: { user, note, lastUpdatedAt }
}) => {
  const { classes } = useStyles();

  return (
    <Flex direction='row' gap='4' data-testid='note'>
      <div>
        <UserAvatar user={user} />
      </div>
      <Flex direction='column' gap='2' flexGrow='1'>
        <Flex className={classes.details} direction='row' gap='2'>
          <Text as='span'>{user?.name || 'Deleted user'}</Text>
          <Text
            as='span'
            className={classes.date}
            title={lightFormat(lastUpdatedAt, 'yyyy-MM-dd @ HH:mm:ss')}
          >
            {`${formatDistanceToNowStrict(lastUpdatedAt)} ago`}
          </Text>
        </Flex>
        <Text as='p' className={classes.note}>
          {note}
        </Text>
      </Flex>
    </Flex>
  );
};
