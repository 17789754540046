import { USER_ROLES, USER_TYPES } from './users';

// quick func to concat a string - used mainly to avoid typos
export const concatUserTypeAndRole = (type: string, role: string) => `${type}.${role}`;

/**
 * CONTROLS SECTION
 */
export const INITIAL_FINDINGS_REQUIRED_ROLES = [
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.ADMIN),
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.OPERATOR)
];

export const UPDATE_STATUS_REQUIRED_ROLES = [
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.ADMIN),
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.OPERATOR),
  concatUserTypeAndRole(USER_TYPES.CUSTOMER, USER_ROLES.ADMIN),
  concatUserTypeAndRole(USER_TYPES.CUSTOMER, USER_ROLES.OPERATOR)
];

export const ADD_CONTROL_NOTES_REQUIRED_ROLES = [
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.ADMIN),
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.OPERATOR),
  concatUserTypeAndRole(USER_TYPES.CUSTOMER, USER_ROLES.ADMIN),
  concatUserTypeAndRole(USER_TYPES.CUSTOMER, USER_ROLES.OPERATOR),
  concatUserTypeAndRole(USER_TYPES.CUSTOMER, USER_ROLES.READER)
];

/**
 * ADMIN SECTION
 */
export const USER_MANAGEMENT_REQUIRED_ROLES = [
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.ADMIN),
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.OPERATOR),
  concatUserTypeAndRole(USER_TYPES.CUSTOMER, USER_ROLES.ADMIN),
  concatUserTypeAndRole(USER_TYPES.CUSTOMER, USER_ROLES.OPERATOR),
  concatUserTypeAndRole(USER_TYPES.CUSTOMER, USER_ROLES.READER)
];

export const USER_MANAGEMENT_CREATE_REQUIRED_ROLES = [
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.ADMIN),
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.OPERATOR),
  concatUserTypeAndRole(USER_TYPES.CUSTOMER, USER_ROLES.ADMIN)
];

export const USER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES = [
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.ADMIN),
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.OPERATOR),
  concatUserTypeAndRole(USER_TYPES.CUSTOMER, USER_ROLES.ADMIN)
];

export const USER_MANAGEMENT_ADD_MODIFY_DELETE_THREATSCAPE_USER_REQUIRED_ROLES = [
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.ADMIN)
];

export const CUSTOMER_MANAGEMENT_REQUIRED_ROLES = [
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.ADMIN)
];

export const CUSTOMER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES = [
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.ADMIN)
];

export const MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES = [
  concatUserTypeAndRole(USER_TYPES.THREATSCAPE, USER_ROLES.ADMIN),
];
