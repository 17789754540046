import { FunctionComponent } from 'react';
import * as FormPrimitive from '@radix-ui/react-form';
import { tss } from 'tss-react';

const useStyles = tss.withName('FormMessage').create(() => ({
  message: {
    color: 'var(--rag-red)',
    fontSize: '14px',
    lineHeight: 1.4
  }
}));

export const FormMessage: FunctionComponent<FormPrimitive.FormMessageProps> = ({
  children,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <FormPrimitive.Message data-testid='form-message' {...props} className={classes.message}>
      {children}
    </FormPrimitive.Message>
  );
};
