import { FunctionComponent } from 'react';
import { Select } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('FormSelect').create(() => ({
  trigger: {
    display: 'flex',
    width: '100%',
    height: '40px',
    padding: '8px 12px',
    backgroundColor: 'var(--bg-inputs)'
  }
}));

export const FormSelect: FunctionComponent<
  Select.RootProps & {
    triggerProps?: Select.TriggerProps;
    items: { label: string; value: string }[];
  }
> = ({ items, triggerProps, ...props }) => {
  const { classes } = useStyles();

  return (
    <Select.Root {...props}>
      <Select.Trigger
        className={classes.trigger}
        data-testid='form-select-trigger'
        {...triggerProps}
      />
      <Select.Content>
        {items.map(({ label, value }, i) => (
          <Select.Item key={i} value={value}>
            {label}
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};
