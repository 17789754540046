import { FunctionComponent } from 'react';
import { CheckCircle, XCircle } from '@phosphor-icons/react';
import { Box, Flex } from '@radix-ui/themes';
import { format, isAfter } from 'date-fns';

import { SolutionIcon } from 'app/components/Controls';
import { CustomerControl, NISPrinciple } from 'app/types/controls';

import { SidePanelDetailsField } from './SidePanelDetailsField';
import { SidePanelSection } from './SidePanelSection';

type DetailsSectionProps = {
  control: CustomerControl;
};

const formatNISValue = (nisPrinciples?: NISPrinciple[]) => {
  if (!nisPrinciples || !nisPrinciples.length) return;
  return (
    <>
      {nisPrinciples.map(({ id, name }) => (
        <span key={`nis-${id}`}>
          {name}
          <br />
        </span>
      ))}
    </>
  );
};

const formatLastUpdatedDateValue = ({ lastUpdatedAt, mostRecentNote }: CustomerControl) => {
  let dateToUse = '';
  if (lastUpdatedAt && mostRecentNote) {
    dateToUse = isAfter(lastUpdatedAt, mostRecentNote) ? lastUpdatedAt : mostRecentNote;
  } else if (!lastUpdatedAt && mostRecentNote) {
    dateToUse = mostRecentNote;
  } else if (!mostRecentNote && lastUpdatedAt) {
    dateToUse = lastUpdatedAt;
  }
  return dateToUse ? format(dateToUse, 'd MMM yyyy') : '';
};

export const DetailsSection: FunctionComponent<DetailsSectionProps> = ({ control }) => {
  return (
    <SidePanelSection label='Details'>
      <Box>
        <Flex gap='2' direction={'column'}>
          <SidePanelDetailsField
            label='Last updated'
            type='text'
            value={formatLastUpdatedDateValue(control)}
          />
          <SidePanelDetailsField
            label='Licenced'
            type='icon'
            value={
              control.licenced ? (
                <CheckCircle
                  size={24}
                  weight='fill'
                  color='var(--rag-light-green)'
                  data-testid='licenced'
                />
              ) : (
                <XCircle
                  size={24}
                  weight='fill'
                  color='var(--rag-red)'
                  opacity={0.3}
                  data-testid='not-licenced'
                />
              )
            }
          />
          <SidePanelDetailsField label='Control version' type='text' value={control.version} />
          <SidePanelDetailsField
            label='Solution'
            type='text'
            value={
              control.solution && (
                <Flex as='span' align='center' gap='8px'>
                  {control.solution?.name}
                  <SolutionIcon solution={control.solution} />
                </Flex>
              )
            }
          />
          <SidePanelDetailsField
            label='NIS principles'
            type='text'
            value={formatNISValue(control.nisPrinciples)}
          />
        </Flex>
      </Box>
    </SidePanelSection>
  );
};
