import { FunctionComponent } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { BusinessImpactTag, SecurityRiskTag, SolutionIcon } from 'app/components';
import { DashboardStatusIcon } from 'app/components/Dashboard';
import { DashboardControl } from 'app/types';

import { isCompletedThisMonth } from './dashboard.utils';

const useStyles = tss.withName('DashboardStatusTooltip').create(() => ({
  tooltipContent: {
    backgroundColor: 'var(--white)',
    borderRadius: '6px',
    border: '1px solid var(--rag-dark-dull)',
    maxWidth: '459px',
    padding: '16px',
    fontSize: '12px',
    '& > span .TooltipArrow': {
      width: '29px',
      height: '14px',
      fill: 'var(--white)',
      marginTop: '-1px',
      paintOrder: 'stroke',
      '& > polygon': {
        stroke: 'var(--rag-dark-dull)'
      }
    },
    '&[data-side="top"]': {
      '& > span svg': {
        marginTop: '-2px'
      }
    }
  },
  controlId: {
    color: 'var(--font-secondary-color)',
    lineHeight: 1.667
  },
  controlName: {
    color: 'var(--font-primary-color)',
    fontSize: '16px',
    lineHeight: 1.5
  },
  controlNote: {
    color: 'var(--font-secondary-color)',
    lineHeight: 1.667,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  solutionBadge: {
    padding: '0 4px',
    borderRadius: '4px',
    border: '1px solid var(--slate-200)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  solutionText: {
    color: 'var(--font-secondary-color)',
    lineHeight: 1.667,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  icon: {
    '--button-ghost-padding-x': 0,
    '--button-ghost-padding-y': 0
  }
}));

type DashboardStatusTooltipProps = {
  control: DashboardControl;
  children: React.ReactNode;
};

export const DashboardStatusTooltip: FunctionComponent<DashboardStatusTooltipProps> = ({
  control,
  children
}) => {
  const { classes } = useStyles();
  const tooltipContainer = document.getElementsByClassName('radix-themes')[0];

  return (
    <Tooltip.Provider skipDelayDuration={0} delayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal container={tooltipContainer}>
          <Tooltip.Content
            data-testid='dashboard-status-tooltip'
            className={classes.tooltipContent}
            side='bottom'
          >
            <Flex gap={'2'} direction={'column'} as='span'>
              <Flex direction={'column'} as='span'>
                <Text className={classes.controlId}>#{control.externalControlId}</Text>
                <Text className={classes.controlName}>{control.name}</Text>
              </Flex>
              <Text className={classes.controlNote}>{control.controlNotes}</Text>
              <Flex gap={'2'} as='span'>
                <DashboardStatusIcon status={isCompletedThisMonth(control)} size={16} />
                <SecurityRiskTag value={control.securityRisk} />
                <BusinessImpactTag value={control.businessRisk} />
                <Flex gap={'2'} align={'center'} className={classes.solutionBadge} as='span'>
                  <SolutionIcon solution={control.solution} size={12} />
                  <Text className={classes.solutionText}>{control.solution.name}</Text>
                </Flex>
              </Flex>
            </Flex>
            <Tooltip.Arrow className='TooltipArrow' />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
