import { FunctionComponent } from 'react';
import { Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { PrimaryButton, SecondaryButton } from '../Buttons';

const useStyles = tss.withName('DeleteTableRow').create(() => ({
  container: {
    borderRadius: '6px',
    border: '1px solid var(--red-a5)',
    background: 'var(--red-a4)',
    display: 'flex',
    padding: '8px'
  },
  text: {
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    lineHeight: 1.71
  },
  button: {
    borderRadius: '6px',
    background: 'var(--red-a5)'
  }
}));

type DeleteTableRowProps = {
  buttonText: string;
  message: string;
  onDelete: () => void;
  onCancel: () => void;
};

export const DeleteTableRow: FunctionComponent<DeleteTableRowProps> = ({
  buttonText,
  message,
  onDelete,
  onCancel
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Flex gap='4' align='center' justify='between' flexGrow='1'>
        <Text className={classes.text}>{message}</Text>
        <Flex gap='4'>
          <SecondaryButton onClick={onCancel} data-testid='delete-cancel-button' title='Cancel'>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            className={classes.button}
            onClick={onDelete}
            data-testid='delete-confirm-button'
            title={buttonText}
          >
            {buttonText}
          </PrimaryButton>
        </Flex>
      </Flex>
    </div>
  );
};
