import { useState } from 'react';
import { PauseCircle, PlayCircle } from '@phosphor-icons/react';
import { IconButton, Spinner } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { USER_STATUS } from 'app/constants/users';
import { useUpdateUserMutation } from 'app/queries/useUserManagementQueries';
import { User } from 'app/types';

const useStyles = tss.withName('UserStatusChange').create(() => ({
  button: {
    margin: 0,
    color: 'var(--font-primary-color)',
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: 'var(--bg-default)'
    }
  }
}));

type UserStatusProps = {
  user: User;
  className: string;
};

export const UserStatusChange: React.FC<UserStatusProps> = ({ user, className }) => {
  const { classes } = useStyles();

  const [isUserDisabled, setIsUserDisabled] = useState(user.status === USER_STATUS.DISABLED);
  const { mutate, isPending } = useUpdateUserMutation();

  const handleMutation = async () => {
    mutate(
      {
        userId: user.id,
        user: {
          status: isUserDisabled ? USER_STATUS.ACTIVE : USER_STATUS.DISABLED,
          email: user.email,
          name: user.name,
          role: user.role
        }
      },
      {
        onSuccess: () => {
          setIsUserDisabled(prevDisabled => !prevDisabled);
        }
      }
    );
  };

  return (
    <>
      {isPending ? (
        <Spinner />
      ) : (
        <IconButton
          onClick={handleMutation}
          disabled={isPending}
          className={clsx(classes.button, className)}
          variant='ghost'
          title={isUserDisabled ? 'Enable user' : 'Disable user'}
        >
          {isUserDisabled ? <PlayCircle size={20} /> : <PauseCircle size={20} />}
        </IconButton>
      )}
    </>
  );
};
