import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { ADMIN_SECTIONS } from 'app/constants/admin';
import {
  CUSTOMER_MANAGEMENT_REQUIRED_ROLES,
  MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES,
  USER_MANAGEMENT_REQUIRED_ROLES
} from 'app/constants/rbac';
import { URLS } from 'app/constants/routes';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';

import { PageWrapper } from './PageWrapper';

const useStyles = tss.withName('AdminCentreWrapper').create(() => ({
  heading: {
    color: 'var(--font-primary-color)',
    fontSize: '18px',
    lineHeight: 1.56,
    fontWeight: 700
  },
  links: {
    display: 'flex',
    borderRadius: '6px',
    border: '1px solid var(--slate-200)',
    background: 'var(--white)',
    padding: '4px 5px',
    '--tab-height': 'auto'
  },
  link: {
    padding: '6px 12px',
    borderRadius: '6px',
    flex: 1,
    color: 'var(--font-primary-color)',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: 1.42,
    textDecoration: 'none',

    '&:hover, &:focus': {
      backgroundColor: 'var(--gray-a3)'
    }
  },
  active: {
    backgroundColor: 'var(--gray-a3)'
  }
}));

const ADMIN_SECTION_HEADINGS = {
  USERS: 'User Management',
  CUSTOMERS: 'Customer Management',
  CONTROLS: 'Master Control Management'
};

type AdminCentrePageWrapperProps = PropsWithChildren & {
  section: ADMIN_SECTIONS;
  toolbar?: ReactNode;
};

export const AdminCentrePageWrapper: FC<AdminCentrePageWrapperProps> = ({
  section,
  toolbar,
  children
}) => {
  const { classes } = useStyles();
  const { isAllowed } = useLoggedInUser();

  const hasAccessToUserManagement = useMemo(
    () => isAllowed(USER_MANAGEMENT_REQUIRED_ROLES),
    [isAllowed]
  );
  const hasAccessToCustomerManagement = useMemo(
    () => isAllowed(CUSTOMER_MANAGEMENT_REQUIRED_ROLES),
    [isAllowed]
  );
  const hasAccessToControlManagement = useMemo(
    () => isAllowed(MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES),
    [isAllowed]
  );

  const hasAccessToMoreThanOneSection = useMemo(
    () =>
      Number(hasAccessToUserManagement) +
        Number(hasAccessToCustomerManagement) +
        Number(hasAccessToControlManagement) >
      1,
    [hasAccessToControlManagement, hasAccessToCustomerManagement, hasAccessToUserManagement]
  );

  return (
    <PageWrapper>
      <Flex gap='4' direction='column' height='fit-content' flexGrow='1'>
        <Heading as='h1' className={classes.heading}>
          {ADMIN_SECTION_HEADINGS[section]}
        </Heading>
        <Flex gap='4' wrap='wrap' flexGrow='1'>
          {hasAccessToMoreThanOneSection && (
            <Box className={classes.links} data-testid='admin-centre-links'>
              {hasAccessToCustomerManagement && (
                <Link
                  to={`/${URLS.ADMIN_CENTRE_CUSTOMERS}`}
                  className={clsx(
                    classes.link,
                    section === ADMIN_SECTIONS.CUSTOMERS && classes.active
                  )}
                >
                  Customers
                </Link>
              )}
              {hasAccessToUserManagement && (
                <Link
                  to={`/${URLS.ADMIN_CENTRE_USERS}`}
                  className={clsx(classes.link, section === ADMIN_SECTIONS.USERS && classes.active)}
                >
                  Users
                </Link>
              )}
              {hasAccessToControlManagement && (
                <Link
                  to={`/${URLS.ADMIN_CENTRE_CONTROLS}`}
                  className={clsx(
                    classes.link,
                    section === ADMIN_SECTIONS.CONTROLS && classes.active
                  )}
                >
                  Controls
                </Link>
              )}
            </Box>
          )}
          {toolbar}
        </Flex>
        {children}
      </Flex>
    </PageWrapper>
  );
};
