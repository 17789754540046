import { FunctionComponent, useMemo } from 'react';
import { Flex, Heading, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { getMessageFromError } from 'app/utils/error-utils';

import { PageWrapper } from '../PageWrappers';

const useStyles = tss.withName('AuthErrorPage').create(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '24px',
    width: '100%'
  }
}));

export const AuthErrorPage: FunctionComponent = () => {
  const { classes } = useStyles();
  const { error } = useLoggedInUser();

  const errorMessage = useMemo(() => getMessageFromError(error), [error]);

  return (
    <PageWrapper>
      <Flex title='Authentication Error' data-testid='auth-error' className={classes.container}>
        <Heading as='h3'>Authentication Error</Heading>
        <Text as='p'>An error has occurred while authenticating your credentials.</Text>
        {error && (
          <>
            <br />
            <Text as='p'>
              <code>{errorMessage}</code>
            </Text>
          </>
        )}
      </Flex>
    </PageWrapper>
  );
};
