import { FunctionComponent } from 'react';
import { Check } from '@phosphor-icons/react';
import { Command as CommandPrimitive } from 'cmdk';

import { SimpleCustomer } from 'app/types';

type SwitchCustomerItemProps = {
  customer: SimpleCustomer;
  classNames: string;
  onSelect: () => void;
};
export const SwitchCustomerItem: FunctionComponent<SwitchCustomerItemProps> = ({
  customer,
  onSelect,
  classNames
}) => {
  return (
    <CommandPrimitive.Item
      key={customer.id}
      value={customer.name}
      className={classNames}
      data-testid='switch-customer-item'
      onSelect={onSelect}
    >
      {customer.name}
      <Check size={16} />
    </CommandPrimitive.Item>
  );
};
