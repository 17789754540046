import { FunctionComponent } from 'react';
import { Container } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { NAVBAR_HEIGHT } from 'app/constants/app';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import Routes from 'app/modules/Routes';

import { AuthErrorPage, UnauthorisedPage } from '../Errors';
import { Navigation } from '../Navigation';
import { PageLoadingIndicator } from '../PageLoadingIndicator';

const useStyles = tss.withName('AppLayout').create(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  main: {
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,

    '&>*': {
      height: '100%',
      maxWidth: '100%',
      width: '100%'
    }
  }
}));

export const AppLayout: FunctionComponent = () => {
  const { classes } = useStyles();
  const {
    isLoading: isUseLoggedInUserLoading,
    error: useLoggedInUserError,
    user
  } = useLoggedInUser();
  const { isLoading: isUseSelectedCustomerLoading, error: useSelectedCustomerError } =
    useSelectedCustomer();

  return (
    <div className={classes.container}>
      <Navigation />
      <Container className={classes.main}>
        {(isUseLoggedInUserLoading || isUseSelectedCustomerLoading) && <PageLoadingIndicator />}

        {(useLoggedInUserError || useSelectedCustomerError) && <AuthErrorPage />}

        {!(isUseLoggedInUserLoading || isUseSelectedCustomerLoading) &&
        !(useLoggedInUserError || useSelectedCustomerError) ? (
          user ? (
            <Routes />
          ) : (
            <UnauthorisedPage />
          )
        ) : (
          <div />
        )}
      </Container>
    </div>
  );
};
