export enum DASHBOARD_STATUS {
  COMPLETED = 'COMPLETED',
  COMPLETED_THIS_MONTH = 'COMPLETED_THIS_MONTH',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  NOT_REQUIRED = 'NOT_REQUIRED'
}

export enum DASHBOARD_STATUS_LABELS {
  COMPLETED = 'Completed',
  COMPLETED_THIS_MONTH = 'Completed (this month)',
  IN_PROGRESS = 'In progress',
  NOT_STARTED = 'Not started',
  NOT_REQUIRED = 'Not required'
}

export enum RISK_VALUE {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW'
}
