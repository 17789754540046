import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from '@radix-ui/themes';
import { isThisMonth } from 'date-fns';
import { tss } from 'tss-react';

import { DashboardStatusIcon } from 'app/components/Dashboard';
import { DASHBOARD_STATUS } from 'app/constants/dashboard';
import { URLS } from 'app/constants/routes';
import { DashboardControl } from 'app/types/dashboard';

import { sortByStatus } from '../dashboard.utils';
import { DashboardStatusTooltip } from '../DashboardStatusTooltip';

const useStyles = tss.withName('ControlsView').create(() => ({
  container: {
    borderRadius: '6px',
    border: '1px solid var(--slate-200)',
    background: 'var(--white)',
    padding: '16px',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
    color: 'var(--font-primary-color)'
  },
  currentNumber: {
    color: 'var(--font-primary-color)',
    fontSize: '41px',
    lineHeight: '41px'
  },
  total: {
    fontSize: '16px',
    lineHeight: '1.06',
    fontWeight: 900
  },
  statusIconLink: {
    display: 'flex'
  }
}));

type ControlsProps = {
  dashboardControls: DashboardControl[];
};

export const Controls: FunctionComponent<ControlsProps> = ({ dashboardControls }) => {
  const { classes } = useStyles();

  const statussesCompletedThisMonth = dashboardControls
    .filter(({ status }) => status === DASHBOARD_STATUS.COMPLETED)
    .filter(({ completedAt }) => completedAt && isThisMonth(completedAt));

  const allStatusesSorted = [
    ...dashboardControls.filter(status => !statussesCompletedThisMonth.includes(status)),
    //change status name from COMPLETED to COMPLETED_THIS_MONTH when completedAt is this month
    ...statussesCompletedThisMonth.map(status => ({
      ...status,
      status: DASHBOARD_STATUS.COMPLETED_THIS_MONTH
    }))
  ].sort(sortByStatus);

  return (
    <Box pt='4' width={'100%'} className={classes.container} data-testid='controls'>
      <Flex gap='5' direction={'column'}>
        <Flex gap='4' align={'center'}>
          <Text className={classes.currentNumber}>{dashboardControls.length}</Text>
          <Text className={classes.total}>
            of {dashboardControls.length}
            <br />
            controls
          </Text>
        </Flex>
        <Flex wrap={'wrap'} align={'center'} gapY={'2'}>
          {allStatusesSorted.map((status, index) => (
            <DashboardStatusTooltip control={status} key={index}>
              <Link
                to={`/${URLS.CONTROLS}?controlId=${status.externalControlId}`}
                className={classes.statusIconLink}
                data-testid='controls-status-link'
              >
                <DashboardStatusIcon status={status.status} />
              </Link>
            </DashboardStatusTooltip>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};
