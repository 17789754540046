import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { CONTROL_STATUS } from 'app/constants/controls';
import { CONTROLS_API_URLS } from 'app/constants/routes';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import { ControlNote, CustomerControl, PagedResponse } from 'app/types';

export const USE_FETCH_CUSTOMER_CONTROLS_QUERY_KEY = 'useFetchCustomerControls';
export const useFetchCustomerControls = (enabled = false) => {
  const { selectedCustomer } = useSelectedCustomer();
  return useQuery({
    queryKey: [USE_FETCH_CUSTOMER_CONTROLS_QUERY_KEY],
    queryFn: () =>
      axiosInstance
        .get<CustomerControl[]>(CONTROLS_API_URLS.ROOT, {
          params: { customerId: selectedCustomer?.id }
        })
        .then(response => response.data),
    enabled
  });
};

export const USE_FETCH_CUSTOMER_CONTROL_QUERY_KEY = 'useFetchCustomerControl';
export const useFetchCustomerControl = (enabled = false, controlId: number) => {
  const { selectedCustomer } = useSelectedCustomer();

  return useQuery({
    queryKey: [USE_FETCH_CUSTOMER_CONTROL_QUERY_KEY, controlId],
    queryFn: async () =>
      await axiosInstance
        .get<CustomerControl>(CONTROLS_API_URLS.SINGLE.replace(':controlId', `${controlId}`), {
          params: { customerId: selectedCustomer?.id }
        })
        .then(response => response.data),
    enabled
  });
};

export const USE_FETCH_CUSTOMER_CONTROL_NOTES_QUERY_KEY = 'useFetchCustomerControlNotes';
export const useFetchCustomerControlNotes = (enabled = false, controlId: number) => {
  const { selectedCustomer } = useSelectedCustomer();
  const options = {
    customerId: selectedCustomer?.id,
    size: 20,
    sort: 'lastUpdatedAt,desc'
  };

  return useInfiniteQuery({
    queryKey: [USE_FETCH_CUSTOMER_CONTROL_NOTES_QUERY_KEY, controlId, options],
    queryFn: async ({ pageParam }) =>
      await axiosInstance
        .get<
          PagedResponse<ControlNote>
        >(CONTROLS_API_URLS.NOTES.replace(':controlId', `${controlId}`), { params: { ...options, page: pageParam } })
        .then(response => response.data),
    enabled,
    gcTime: 1000,
    initialPageParam: 0,
    getNextPageParam: ({ page }) => (page.totalPages > page.number + 1 ? page.number + 1 : null)
  });
};

export interface InitialFindingsMutationOptions {
  externalControlId: number;
  initialFindings: string;
}
export const useInitialFindingsMutation = () => {
  const { selectedCustomer } = useSelectedCustomer();

  return useMutation<CustomerControl, unknown, InitialFindingsMutationOptions>({
    mutationKey: ['useInitialFindingsMutation'],
    mutationFn: async ({ externalControlId, initialFindings }: InitialFindingsMutationOptions) =>
      await axiosInstance
        .put<CustomerControl>(
          CONTROLS_API_URLS.INITIAL_FINDINGS.replace(':controlId', `${externalControlId}`),
          {
            customerId: selectedCustomer?.id,
            initialFindings
          }
        )
        .then(response => response.data)
  });
};

export interface StatusMutationOptions {
  externalControlId: number;
  status: CONTROL_STATUS;
}
export const useStatusMutation = () => {
  const { selectedCustomer } = useSelectedCustomer();
  const queryClient = useQueryClient();

  return useMutation<CustomerControl, unknown, StatusMutationOptions>({
    mutationKey: ['useStatusMutation'],
    mutationFn: async ({ externalControlId, status }: StatusMutationOptions) =>
      await axiosInstance
        .put<CustomerControl>(
          CONTROLS_API_URLS.STATUS.replace(':controlId', `${externalControlId}`),
          {
            customerId: selectedCustomer?.id,
            status
          }
        )
        .then(response => response.data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [USE_FETCH_CUSTOMER_CONTROLS_QUERY_KEY] })
  });
};

export interface CustomerControlNotesMutationOptions {
  externalControlId: number;
  note: string;
}
export const useCustomerControlNotesMutation = () => {
  const { selectedCustomer } = useSelectedCustomer();
  const queryClient = useQueryClient();

  return useMutation<ControlNote, unknown, CustomerControlNotesMutationOptions>({
    mutationKey: ['useCustomerControlNotesMutation'],
    mutationFn: async ({ externalControlId, note }: CustomerControlNotesMutationOptions) =>
      await axiosInstance
        .post<ControlNote>(CONTROLS_API_URLS.NOTES.replace(':controlId', `${externalControlId}`), {
          customerId: selectedCustomer?.id,
          note
        })
        .then(response => response.data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [USE_FETCH_CUSTOMER_CONTROL_NOTES_QUERY_KEY] })
  });
};
