import { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, Flex, Grid, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { SolutionIcon } from 'app/components';
import { DashboardStatusIcon } from 'app/components/Dashboard';
import { DASHBOARD_STATUS } from 'app/constants/dashboard';
import { URLS } from 'app/constants/routes';
import { DashboardControl } from 'app/types';
import { buildSearchParamsFromFilters } from 'app/utils/control-utils';

import { groupBySolution, isCompletedThisMonth } from '../dashboard.utils';
import { DashboardStatusTooltip } from '../DashboardStatusTooltip';

const useStyles = tss.withName('Solutions').create(() => ({
  link: {
    color: 'var(--font-primary-color)',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline'
    }
  },
  card: {
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  title: {
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    lineHeight: 1.43
  },
  count: {
    color: 'var(--font-secondary-color)',
    fontSize: '12px',
    lineHeight: 1.667,
	flex: 1,
    alignItems: 'flex-end',
    display: 'flex'
  },
  solutionIcon: {
    flexShrink: 0
  },
  statusIconLink: {
    display: 'flex'
  }
}));

type SolutionsProps = {
  dashboardControls: DashboardControl[];
};

export const Solutions: FunctionComponent<SolutionsProps> = ({ dashboardControls }) => {
  const { classes } = useStyles();

  const solutionGroups = useMemo(() => groupBySolution(dashboardControls), [dashboardControls]);

  return (
    <Grid gap='4' columns={'repeat(auto-fit, minmax(246px, 1fr))'} data-testid='solutions'>
      {solutionGroups.map(solutionGroup => (
        <Card className={classes.card} key={solutionGroup.solution?.id}>
          <Link
            to={`/${URLS.CONTROLS}?${buildSearchParamsFromFilters({ solutionId: solutionGroup.solution?.id.toString() })}`}
            className={classes.link}
            data-testid='solution-card-link'
          >
            <Text className={classes.title}>
              <Flex gap={'2'} align={'start'}>
                <SolutionIcon solution={solutionGroup.solution} className={classes.solutionIcon} />
                {solutionGroup.solution?.name}
              </Flex>
            </Text>
          </Link>
          <Flex gap='1' wrap={'wrap'}>
            {solutionGroup.controls.map((control, index) => (
              <DashboardStatusTooltip control={control} key={index}>
                <Link
                  to={`/${URLS.CONTROLS}?controlId=${control.externalControlId}`}
                  className={classes.statusIconLink}
                  data-testid='solutions-tooltip-link'
                >
                  <DashboardStatusIcon status={isCompletedThisMonth(control)} size={16} />
                </Link>
              </DashboardStatusTooltip>
            ))}
          </Flex>
          <Text className={classes.count}>
            {
              solutionGroup.controls.filter(
                control => control.status === DASHBOARD_STATUS.COMPLETED
              ).length
            }
            {' of '}
            {solutionGroup.controls.length} completed
          </Text>
        </Card>
      ))}
    </Grid>
  );
};
