import { FormEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Warning } from '@phosphor-icons/react';
import * as FormPrimitive from '@radix-ui/react-form';
import { Callout } from '@radix-ui/themes';

import {
  FormField,
  FormInput,
  FormLabel,
  FormMessage,
  FormPanel,
  FormRadioGroup,
  FormSelect
} from 'app/components/Forms';
import { AdminCentrePageWrapper } from 'app/components/PageWrappers/AdminCentrePageWrapper';
import { ADMIN_SECTIONS } from 'app/constants/admin';
import { USER_MANAGEMENT_ADD_MODIFY_DELETE_THREATSCAPE_USER_REQUIRED_ROLES } from 'app/constants/rbac';
import { URLS } from 'app/constants/routes';
import { USER_ROLE_OPTIONS, USER_TYPE_OPTIONS, USER_TYPES } from 'app/constants/users';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useFetchCustomers } from 'app/queries/useCustomerQueries';
import { useCreateUserMutation } from 'app/queries/useUserManagementQueries';
import { CreateUserRequest } from 'app/types/users';
import { getMessageFromError } from 'app/utils/error-utils';

export const CreateUser: FunctionComponent = () => {
  const navigate = useNavigate();
  const { isThreatscapeUser, isAllowed } = useLoggedInUser();

  const [showCustomers, setShowCustomers] = useState(false);
  const [showTypeError, setShowTypeError] = useState(false);

  const { data: customerData, isLoading: isCustomerDataLoading } = useFetchCustomers(showCustomers);

  const { mutate, isPending, isError, error } = useCreateUserMutation();

  const isAllowedCreateThreatscape = useMemo(
    () => isAllowed(USER_MANAGEMENT_ADD_MODIFY_DELETE_THREATSCAPE_USER_REQUIRED_ROLES),
    [isAllowed]
  );

  const handleUserTypeChange = (val: string) => {
    setShowTypeError(!val);
    setShowCustomers(val === USER_TYPES.CUSTOMER);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    // prevent default form submission
    event.preventDefault();

    const newUser = Object.fromEntries(
      new FormData(event.currentTarget)
    ) as unknown as CreateUserRequest;

    mutate(
      {
        user: { ...newUser, ...(!isAllowedCreateThreatscape ? { type: USER_TYPES.CUSTOMER } : {}) }
      },
      {
        onSuccess: () => {
          navigate(`/${URLS.ADMIN_CENTRE_USERS}`);
        }
      }
    );
  };

  const handleCancel = () => {
    // redirect to list page
    navigate(`/${URLS.ADMIN_CENTRE_USERS}`);
  };

  useEffect(() => {
    if (isThreatscapeUser && !isAllowedCreateThreatscape) {
      setShowCustomers(true);
    }
  }, [isThreatscapeUser, isAllowedCreateThreatscape]);

  return (
    <AdminCentrePageWrapper section={ADMIN_SECTIONS.USERS}>
      <FormPanel
        heading='New User'
        onSubmit={handleSubmit}
        onCancelClick={handleCancel}
        isSubmitting={isPending}
      >
        {isError && (
          <Callout.Root color='red' size='1' data-testid='user-form-error'>
            <Callout.Icon>
              <Warning />
            </Callout.Icon>
            <Callout.Text>Unable to create user.</Callout.Text>
            {error && <Callout.Text>{getMessageFromError(error)}</Callout.Text>}
          </Callout.Root>
        )}

        <FormField name='name' data-testid='user-name-field'>
          <FormLabel>Name</FormLabel>
          <FormInput name='name' required disabled={isPending} />
          <FormMessage match='valueMissing'>This field is required</FormMessage>
        </FormField>

        <FormField name='email' data-testid='user-email-field'>
          <FormLabel>Email</FormLabel>
          <FormInput name='email' type='email' required disabled={isPending} />
          <FormMessage match='valueMissing'>This field is required</FormMessage>
          <FormMessage match='typeMismatch'>Please enter a valid email</FormMessage>
        </FormField>

        {isThreatscapeUser && (
          <>
            {isAllowedCreateThreatscape && (
              <FormPrimitive.Field name='type' data-testid='user-type-field'>
                <FormLabel id='user-type-label'>User Type</FormLabel>
                <FormRadioGroup
                  aria-labelledby='user-type-label'
                  name='type'
                  required
                  disabled={isPending}
                  onInvalid={() => setShowTypeError(true)}
                  items={USER_TYPE_OPTIONS}
                  onValueChange={handleUserTypeChange}
                />
                <FormMessage match='valueMissing' forceMatch={showTypeError}>
                  This field is required
                </FormMessage>
              </FormPrimitive.Field>
            )}

            {showCustomers && (
              <FormPrimitive.Field name='customerId' data-testid='user-customerId-field'>
                <FormLabel id='user-customer-label'>Customer</FormLabel>
                <FormSelect
                  name='customerId'
                  required
                  defaultValue={`${(customerData || [])[0]?.id}`}
                  triggerProps={{ 'aria-labelledby': 'user-customer-label' }}
                  items={(customerData || []).map(val => ({ value: `${val.id}`, label: val.name }))}
                  disabled={isCustomerDataLoading || isPending}
                />
                <FormMessage match='valueMissing'>This field is required</FormMessage>
              </FormPrimitive.Field>
            )}
          </>
        )}

        <FormPrimitive.Field name='role' data-testid='user-role-field'>
          <FormLabel id='user-role-label'>Role</FormLabel>
          <FormSelect
            name='role'
            required
            disabled={isPending}
            defaultValue={USER_ROLE_OPTIONS[0].value}
            triggerProps={{ 'aria-labelledby': 'user-role-label' }}
            items={USER_ROLE_OPTIONS}
          />
          <FormMessage match='valueMissing'>This field is required</FormMessage>
        </FormPrimitive.Field>
      </FormPanel>
    </AdminCentrePageWrapper>
  );
};
