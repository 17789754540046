import { FunctionComponent } from 'react';
import { Avatar, AvatarProps } from '@radix-ui/themes';

import { SimpleUser } from 'app/types';

export const UserAvatar: FunctionComponent<{ user?: SimpleUser | null }> = ({ user }) => {
  const userToUse = {
    name: 'Deleted user',
    avatarColour: 'gray',
    ...user
  } as SimpleUser;

  return (
    <Avatar
      style={{ width: 28, height: 28 }}
      variant='solid'
      radius='full'
      size='1'
      color={userToUse.avatarColour as AvatarProps['color']}
      fallback={userToUse.name.charAt(0)}
    />
  );
};
