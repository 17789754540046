import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Warning } from '@phosphor-icons/react';
import { Callout } from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { tss } from 'tss-react';

import {
  CustomerControlCard,
  EmptyResult,
  PageLoadingIndicator,
  PageWrapper
} from 'app/components';
import { CONTROL_SORT_OPTIONS } from 'app/constants/controls';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import {
  USE_FETCH_CUSTOMER_CONTROLS_QUERY_KEY,
  useFetchCustomerControls
} from 'app/queries/useControlQueries';
import { CustomerControlFilters } from 'app/types';
import { CustomerControl } from 'app/types/controls';
import {
  buildFiltersFromSearchParams,
  buildSearchParamsFromFilters,
  filterControls,
  sortControls
} from 'app/utils/control-utils';

import { ControlSidePanel } from './ControlDetails/ControlSidePanel';
import { FilterPanel } from './Filters/FilterPanel';

const useStyles = tss.withName('ControlsList').create(() => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 80%',
    gap: '16px',
    minWidth: 0
  },
  withSideContent: {
    flex: '1 1 40%'
  },
  bottomSpace: {
    height: '1px',
    flex: 'none'
  },
  filters: {
    flex: '1 1 20%',
    position: 'sticky',
    top: 0
  },
  sideContent: {
    flex: '1 1 40%',
    position: 'sticky',
    top: 0,
    display: 'flex',
    height: '100%'
  },
  error: {
    border: '1px solid var(--accent-a6)',
    boxShadow: '0px 4px 4px 0px var(--box-shadow-color)'
  },
  empty: {
    border: '1px solid var(--slate-200)',
    boxShadow: '0px 4px 4px 0px var(--box-shadow-color)'
  },
  emptyTitle: {
    color: 'var(--font-primary-color)',
    fontSize: '18px',
    lineHeight: 1.5
  }
}));

export const Controls: FunctionComponent = () => {
  const { classes } = useStyles();
  const queryClient = useQueryClient();
  const { selectedCustomer } = useSelectedCustomer();

  // allows us to delay the query until we are ready (i.e. we have any filters from searchparams)
  const [ready, setReady] = useState(false);
  // get any filters from urlsearchparams
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<CustomerControlFilters>({});
  const [sort, setSort] = useState<string>(CONTROL_SORT_OPTIONS[0].value);

  const { data, isFetching, error } = useFetchCustomerControls(ready);

  const [selectedControl, setSelectedControl] = useState<number | undefined>();

  const handleFilterChange = (newFilters: CustomerControlFilters) => {
    // this will also trigger the useEffect below which calls setFilters
    setSearchParams(buildSearchParamsFromFilters(newFilters, searchParams));
  };

  const handleSortChange = (newSort: string) => {
    searchParams.set('sort', newSort);
    setSearchParams(searchParams);
  };

  const handleOpenDetails = ({ externalControlId }: CustomerControl) => {
    // if the externalControlId is already the selected value, then clear the value
    if (selectedControl === externalControlId) {
      searchParams.delete('controlId');
      setSelectedControl(undefined);
    } else {
      searchParams.set('controlId', `${externalControlId}`);
    }
    setSearchParams(searchParams);
  };

  const filteredData = useMemo(() => {
    if (isFetching) return data || [];
    return sortControls(filterControls(data || [], filters), sort);
  }, [data, filters, isFetching, sort]);

  useEffect(() => {
    setFilters(buildFiltersFromSearchParams(searchParams));
    setSort(searchParams.get('sort') || CONTROL_SORT_OPTIONS[0].value);

    const controlId = searchParams.get('controlId');
    if (controlId) {
      setSelectedControl(Number.parseInt(controlId));
    }

    setReady(true);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [USE_FETCH_CUSTOMER_CONTROLS_QUERY_KEY] });
  }, [queryClient, selectedCustomer]);

  return (
    <PageWrapper>
      <div className={classes.filters}>
        {ready && (
          <FilterPanel
            filters={filters}
            onFilterChange={handleFilterChange}
            sort={sort}
            onSortChange={handleSortChange}
          />
        )}
      </div>
      <div className={clsx(classes.main, selectedControl && classes.withSideContent)}>
        {isFetching && <PageLoadingIndicator />}
        {!isFetching && !error && filteredData.length === 0 && (
          <EmptyResult content={'Please try another search term or filter combination.'} />
        )}
        {filteredData.map((val: CustomerControl) => (
          <CustomerControlCard
            key={val.externalControlId}
            control={val}
            isActiveCard={selectedControl === val.externalControlId}
            onClick={handleOpenDetails}
          />
        ))}
        {!isFetching && error && (
          <Callout.Root
            color='red'
            role='alert'
            variant='surface'
            size='3'
            className={classes.error}
            data-testid='controls-error'
          >
            <Callout.Icon>
              <Warning size={32} weight='fill' />
            </Callout.Icon>
            <Callout.Text>An unexpected error occurred.</Callout.Text>
            <Callout.Text>Please try refreshing the page.</Callout.Text>
          </Callout.Root>
        )}
        <div className={classes.bottomSpace}></div>
      </div>
      {selectedControl && (
        <div className={classes.sideContent}>
          <ControlSidePanel externalControlId={selectedControl} />
        </div>
      )}
    </PageWrapper>
  );
};
