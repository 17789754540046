import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { concatUserTypeAndRole } from 'app/constants/rbac';
import { USER_TYPES } from 'app/constants/users';
import { useFetchLoggedInUser } from 'app/queries/useUserQueries';
import { ApiError } from 'app/types/ApiError';
import { User } from 'app/types/users';

type LoggedInUserContextType = {
  isLoading: boolean;
  error?: Error | ApiError | null;
  user?: User;
  isAllowed: (allowedRoles: string[]) => boolean;
  isThreatscapeUser: boolean;
};

const LoggedInUserContext = createContext<LoggedInUserContextType>({
  isLoading: true
} as LoggedInUserContextType);

export const LoggedInUserProvider = (props: PropsWithChildren) => {
  const { isLoading, data, error } = useFetchLoggedInUser();

  const isThreatscapeUser = useMemo(() => data?.type === USER_TYPES.THREATSCAPE, [data]);

  return (
    <LoggedInUserContext.Provider
      value={{
        isLoading,
        user: data,
        error,
        isAllowed: (allowedRoles: string[]) =>
          data ? allowedRoles.includes(concatUserTypeAndRole(data.type, data.role)) : false,
        isThreatscapeUser
      }}
    >
      {props.children}
    </LoggedInUserContext.Provider>
  );
};

export const useLoggedInUser = () => useContext(LoggedInUserContext);
