import { FunctionComponent, useEffect } from 'react';
import { Flex } from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';

import { ConnectionError, PageLoadingIndicator, PageWrapper } from 'app/components';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import {
  USE_FETCH_DASHBOARD_CONTROLS_QUERY_KEY,
  useFetchDashboardControls
} from 'app/queries/useDashboardControlsQueries';

import { ActionCards } from './ActionCards/ActionCards';
import { Controls } from './Controls/Controls';
import { ProgressReport } from './ProgressReport/ProgressReport';
import { Solutions } from './Solutions/Solutions';

export const Dashboard: FunctionComponent = () => {
  const queryClient = useQueryClient();
  const { selectedCustomer } = useSelectedCustomer();

  const { data, isFetching, error } = useFetchDashboardControls(!!selectedCustomer);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [USE_FETCH_DASHBOARD_CONTROLS_QUERY_KEY] });
  }, [queryClient, selectedCustomer]);

  return (
    <PageWrapper>
      {isFetching && <PageLoadingIndicator />}
      {error && (
        <div data-testid='dashboard-error'>
          <ConnectionError />
        </div>
      )}
      {data && (
        <Flex gap={'4'} direction={'column'} height={'fit-content'}>
          <ActionCards dashboardControls={data} />
          <ProgressReport dashboardControls={data} />
          <Controls dashboardControls={data} />
          <Solutions dashboardControls={data} />
        </Flex>
      )}
    </PageWrapper>
  );
};
