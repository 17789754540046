import { FunctionComponent, useEffect } from 'react';
import { Card, Flex, Heading, Text } from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import { tss } from 'tss-react';

import {
  BusinessImpactTag,
  EmptyResult,
  PageLoadingIndicator,
  SecurityRiskTag
} from 'app/components';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import {
  USE_FETCH_CUSTOMER_CONTROL_QUERY_KEY,
  useFetchCustomerControl
} from 'app/queries/useControlQueries';

import { SidePanelTabs } from './SidePanelTabs';

const useStyles = tss.withName('ControlSidePanel').create(() => ({
  container: {
    background: 'var(--white)',
    borderColor: 'var(--slate-200)',
    padding: '16px',
    boxShadow: '0px 4px 4px 0px var(--box-shadow-color)',
    width: '100%'
  },
  innerContainer: {
    overflow: 'auto',
    height: '100%'
  },
  info: {
    paddingBottom: '16px',
    borderBottom: '1px solid var(--slate-200)'
  },
  id: {
    color: 'var(--font-secondary-color)',
    fontSize: '12px',
    lineHeight: 1.667
  },
  heading: {
    color: 'var(--slate-900)',
    fontSize: '20px',
    lineHeight: 1.4,
    fontWeight: 700
  },
  controlNote: {
    color: 'var(--font-secondary-color)',
    fontSize: '12px',
    lineHeight: 1.667,
    fontWeight: 400
  }
}));

type ControlSidePanelProps = {
  externalControlId: number;
};

export const ControlSidePanel: FunctionComponent<ControlSidePanelProps> = ({
  externalControlId
}) => {
  const { classes } = useStyles();
  const queryClient = useQueryClient();
  const { selectedCustomer } = useSelectedCustomer();

  const { data, isFetching, error } = useFetchCustomerControl(true, externalControlId);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [USE_FETCH_CUSTOMER_CONTROL_QUERY_KEY] });
  }, [queryClient, selectedCustomer]);

  return (
    <Card
      key={externalControlId}
      className={classes.container}
      data-testid='customer-control-side-panel'
    >
      {isFetching && <PageLoadingIndicator />}
      {error && (
        <EmptyResult title='Control not found' content='Please select a control from the list.' />
      )}
      {data && (
        <Flex className={classes.innerContainer} direction='column' gap='2'>
          <Flex direction='column' gap='2' className={classes.info}>
            <Flex direction={'column'}>
              <Text as='span' className={classes.id}>
                #{data.externalControlId}
              </Text>
              <Heading as='h3' className={classes.heading}>
                {data.name}
              </Heading>
            </Flex>
            <Text as='p' className={classes.controlNote}>
              {data.controlNotes}
            </Text>
            <Flex gap='2' align='center'>
              {data.securityRisk && <SecurityRiskTag value={data.securityRisk} />}
              {data.businessRisk && <BusinessImpactTag value={data.businessRisk} />}
            </Flex>
          </Flex>
          <SidePanelTabs control={data} />
        </Flex>
      )}
    </Card>
  );
};
