import { FunctionComponent } from 'react';
import { Flex } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { FreeTextFilter } from 'app/components/Filters';
import { CustomerControlFilters } from 'app/types';

import { SolutionFilter } from './SolutionFilter';
import { SortSelect } from './SortSelect';
import { StatusFilter } from './StatusFilter';

const useStyles = tss.withName('FilterPanel').create(() => ({
  container: {
    background: 'var(--slate-200)',
    padding: '8px 8px 16px',
    borderRadius: 'var(--radius-4)'
  }
}));

type FilterPanelProps = {
  filters: CustomerControlFilters;
  sort: string;
  onFilterChange: (newFilters: CustomerControlFilters) => void;
  onSortChange: (newSort: string) => void;
};

export const FilterPanel: FunctionComponent<FilterPanelProps> = ({
  filters,
  onFilterChange,
  sort,
  onSortChange
}) => {
  const { classes } = useStyles();

  return (
    <Flex direction='column' gap='3' className={classes.container}>
      <FreeTextFilter
        defaultValue={filters.freeText}
        onChange={(value: string) =>
          onFilterChange({
            ...filters,
            freeText: value
          })
        }
      />
      <SortSelect defaultValue={sort} onChange={onSortChange} />
      <SolutionFilter
        defaultValue={filters.solutionId}
        onChange={(value: string) =>
          onFilterChange({
            ...filters,
            solutionId: value === 'all' ? '' : value
          })
        }
      />
      <StatusFilter
        defaultValue={filters.status}
        onChange={(value: string) =>
          onFilterChange({
            ...filters,
            status: value
          })
        }
      />
    </Flex>
  );
};
