import { FunctionComponent, useMemo, useState } from 'react';
import { PaperPlaneRight, Warning } from '@phosphor-icons/react';
import { Button, Callout, Flex, Spinner, TextArea } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { ADD_CONTROL_NOTES_REQUIRED_ROLES } from 'app/constants/rbac';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useCustomerControlNotesMutation } from 'app/queries/useControlQueries';
import { ControlNote, CustomerControl } from 'app/types/controls';

const useStyles = tss.withName('AddNote').create(() => ({
  textarea: {
    minHeight: '74px',
    boxShadow: 'inset 0 0 0 var(--text-area-border-width) var(--slate-200)',
    backgroundColor: 'var(--bg-inputs)',
    width: '100%',

    '&>textarea': {
      padding: '8px 12px'
    }
  },
  save: {
    height: '28px',
    padding: '6px',
    cursor: 'pointer',
    backgroundColor: 'var(--slate-700)',

    '&[data-disabled=true]': {
      backgroundColor: 'var(--slate-500)',
      color: 'var(--white)',
      cursor: 'not-allowed'
    }
  }
}));

type AddNoteProps = {
  control: CustomerControl;
  onNotesChange: (newNote: ControlNote) => void;
};

export const AddNote: FunctionComponent<AddNoteProps> = ({ control, onNotesChange }) => {
  const { classes } = useStyles();
  const { isAllowed } = useLoggedInUser();

  const [newValue, setNewValue] = useState('');

  const { mutate, isPending, isError } = useCustomerControlNotesMutation();

  const userIsAllowed = useMemo(() => isAllowed(ADD_CONTROL_NOTES_REQUIRED_ROLES), [isAllowed]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      { externalControlId: control.externalControlId, note: newValue || '' },
      {
        onSuccess: response => {
          onNotesChange(response);
          setNewValue('');
        }
      }
    );
  };

  return (
    <>
      {userIsAllowed && (
        <form onSubmit={handleSubmit}>
          <Flex gap='2' direction='column'>
            <Flex gap='2' direction='row' align='end'>
              <TextArea
                className={classes.textarea}
                resize='vertical'
                color='gray'
                disabled={isPending}
                maxLength={65535}
                value={newValue}
                onChange={e => setNewValue(e.target.value)}
                data-testid='add-note-input'
              />
              <Flex gap='2' direction='column' align='center'>
                {isPending && <Spinner />}
                <Button
                  color='gray'
                  radius='full'
                  className={classes.save}
                  type='submit'
                  disabled={isPending || !/\S/.test(newValue)}
                  data-testid='submit-btn'
                >
                  <PaperPlaneRight size={16} weight='fill' />
                </Button>
              </Flex>
            </Flex>
            {isError && (
              <Callout.Root color='red' size='1' data-testid='add-note-error'>
                <Callout.Icon>
                  <Warning />
                </Callout.Icon>
                <Callout.Text>Unable to save note.</Callout.Text>
              </Callout.Root>
            )}
          </Flex>
        </form>
      )}
    </>
  );
};
