import { FunctionComponent } from 'react';
import { ChatText, ListDashes } from '@phosphor-icons/react';
import { Box, Flex, Tabs } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { ControlNote, CustomerControl } from 'app/types/controls';

import { NotesList } from './NotesTab/NotesList';
import { DetailsSection, InitialFindingsSection, StatusSection } from './DetailsTab';

const useStyles = tss.withName('SidePanelTabs').create(() => ({
  list: {
    backgroundColor: 'var(--bg-default)',
    padding: '5px',
    borderRadius: '6px',
    boxShadow: 'none',
    gap: '5px'
  },
  trigger: {
    padding: '6px 12px',
    borderRadius: '6px',
    flex: 1,
    color: 'var(--font-primary-color)',
    cursor: 'pointer',

    '& > span': {
      fontSize: '14px',
      backgroundColor: 'transparent',
      display: 'flex',
      gap: '10px',
      fontWeight: 700,
      padding: '0'
    },

    '&::before': {
      content: 'none'
    },

    '&:focus, &[data-state=active]': {
      backgroundColor: 'var(--white)'
    },

    '&:hover': {
      backgroundColor: 'var(--gray-a3)'
    }
  }
}));

type SidePanelTabsProps = {
  control: CustomerControl;
};

export const SidePanelTabs: FunctionComponent<SidePanelTabsProps> = ({ control }) => {
  const { classes } = useStyles();

  const handleControlChange = (updatedControl: CustomerControl) => {
    control.lastUpdatedAt = updatedControl.lastUpdatedAt;
  };

  const handleNotesChange = (newNote: ControlNote) => {
    control.mostRecentNote = newNote.lastUpdatedAt;
  };

  return (
    <Tabs.Root defaultValue='details'>
      <Tabs.List className={classes.list}>
        <Tabs.Trigger value='details' className={classes.trigger} data-testid='details-tab'>
          <ListDashes width='16' height='16' weight='bold' />
          Details
        </Tabs.Trigger>
        <Tabs.Trigger value='notes' className={classes.trigger} data-testid='notes-tab'>
          <ChatText width='16' height='16' weight='fill' />
          Notes
        </Tabs.Trigger>
      </Tabs.List>

      <Box pt='2'>
        <Tabs.Content value='details'>
          <Flex gap='4' justify='between' direction='column'>
            <StatusSection control={control} onControlChange={handleControlChange} />
            <InitialFindingsSection control={control} onControlChange={handleControlChange} />
            <DetailsSection control={control} />
          </Flex>
        </Tabs.Content>

        <Tabs.Content value='notes'>
          <NotesList control={control} onNotesChange={handleNotesChange} />
        </Tabs.Content>
      </Box>
    </Tabs.Root>
  );
};
