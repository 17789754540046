import { FunctionComponent, ReactNode } from 'react';
import { FlagBannerFold, Siren } from '@phosphor-icons/react';
import { Box, Card, Flex, Text } from '@radix-ui/themes';
import { clsx } from 'clsx';
import { tss } from 'tss-react';

const useStyles = tss.withName('ActionCards').create(() => ({
  card: {
    display: 'flex',
    gap: '16px',
    padding: '0',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)'
  },
  cardName: {
    width: '120px',
    padding: '16px',
    gap: '8px',
    color: 'var(--font-negative-color)',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cardIncomplete: {
    color: 'var(--font-primary-color)'
  },
  actions: {
    background: 'var(--white)',
    padding: '8px 0'
  },
  quickWins: {
    border: '1px solid var(--rag-purple)',
    '& > div:first-child': {
      background: 'var(--rag-purple)'
    }
  },
  highRisk: {
    border: '1px solid var(--red-a5)',
    '& > div:first-child': {
      background: 'var(--red-a5)'
    }
  },
  incomplete: {
    border: '1px solid var(--slate-200)',
    '& > div:first-child': {
      background: 'var(--slate-200)'
    }
  },
  title: {
    fontSize: '16px',
    fontWeight: 900,
    lineHeight: '12px',
    letterSpacing: '-0.12px'
  },
  count: {
    fontSize: '41px',
    lineHeight: '29px',
    letterSpacing: '-4.1px'
  }
}));

type SolutionsProps = {
  children: ReactNode;
  variant: 'quickWins' | 'highRisk' | 'incomplete';
  cardLength: number;
};

const getVariantTitle = (variant: SolutionsProps['variant']) => {
  switch (variant) {
    case 'quickWins':
      return 'Quick wins';
    case 'highRisk':
      return 'High risk';
    case 'incomplete':
      return 'Incomplete';
  }
};

const getVariantIcon = (variant: SolutionsProps['variant']) => {
  switch (variant) {
    case 'quickWins':
      return <FlagBannerFold size={24} weight='fill' />;
    case 'highRisk':
      return <Siren size={24} weight='fill' />;
    case 'incomplete':
      return;
  }
};

export const ActionCard: FunctionComponent<SolutionsProps> = ({
  children,
  variant,
  cardLength
}) => {
  const { classes } = useStyles();

  return (
    <Card className={clsx(classes.card, classes[variant])} variant='classic'>
      <Box className={clsx(classes.cardName, variant === 'incomplete' && classes.cardIncomplete)}>
        <Flex gap='2' direction={'column'} align={'stretch'}>
          <Text className={classes.title}>{getVariantTitle(variant)}</Text>
          <Flex justify={variant === 'incomplete' ? 'end' : 'between'} align={'center'}>
            {getVariantIcon(variant)}
            <Text className={classes.count} data-testid='action-card-count'>
              {cardLength}
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Flex gap='2' className={clsx(classes.actions)} direction={'column'}>
        {children}
      </Flex>
    </Card>
  );
};
