import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { DASHBOARD_CONTROLS_API_ROOT } from 'app/constants/routes';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import { DashboardControl } from 'app/types';

export const USE_FETCH_DASHBOARD_CONTROLS_QUERY_KEY = 'dashboard-controls';
export const useFetchDashboardControls = (enabled = false) => {
  const { selectedCustomer } = useSelectedCustomer();

  return useQuery({
    queryKey: [USE_FETCH_DASHBOARD_CONTROLS_QUERY_KEY],
    queryFn: async () => {
      const response = await axiosInstance.get<DashboardControl[]>(
        DASHBOARD_CONTROLS_API_ROOT.ROOT,
        { params: { customerId: selectedCustomer?.id } }
      );
      return response.data;
    },
    enabled
  });
};
