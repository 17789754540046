import { FunctionComponent, useMemo } from 'react';
import { Text } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { USER_STATUS } from 'app/constants/users';

const useStyles = tss.withName('StatusBadge').create(() => ({
  badge: {
    padding: '4px',
    fontSize: '12px',
    fontWeight: 900,
    letterSpacing: '1.2px',
    lineHeight: 1.667,
    borderRadius: '4px'
  },
  badgeActive: {
    color: 'var(--green-a10)',
    backgroundColor: 'var(--green-a4)'
  },
  badgeRegistered: {
    color: 'var(--amber-a8)',
    backgroundColor: 'var(--amber-a4)'
  },
  badgeDisabled: {
    color: 'var(--font-primary-color)',
    backgroundColor: 'var(--slate-200)'
  }
}));

type StatusBadgeProps = {
  status: USER_STATUS;
};
export const StatusBadge: FunctionComponent<StatusBadgeProps> = ({ status }) => {
  const { classes } = useStyles();

  const badgeClass = useMemo(
    () =>
      status === USER_STATUS.ACTIVE
        ? classes.badgeActive
        : status === USER_STATUS.REGISTERED
          ? classes.badgeRegistered
          : classes.badgeDisabled,
    [classes, status]
  );

  return <Text className={clsx(classes.badge, badgeClass)}>{status}</Text>;
};
