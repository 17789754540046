import { FormEvent, FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Warning } from '@phosphor-icons/react';
import * as FormPrimitive from '@radix-ui/react-form';
import { Callout } from '@radix-ui/themes';

import {
  FormField,
  FormInput,
  FormLabel,
  FormMessage,
  FormPanel,
  FormRadioGroup
} from 'app/components/Forms';
import { AdminCentrePageWrapper } from 'app/components/PageWrappers/AdminCentrePageWrapper';
import { ADMIN_SECTIONS } from 'app/constants/admin';
import { LICENCE_OPTIONS } from 'app/constants/customers';
import { URLS } from 'app/constants/routes';
import { useCreateCustomerMutation } from 'app/queries/useCustomerManagementQueries';
import { CreateCustomerRequest } from 'app/types/customers';
import { getMessageFromError } from 'app/utils/error-utils';

export const CreateCustomer: FunctionComponent = () => {
  const navigate = useNavigate();

  const [showCustomerLicenceError, setShowCustomerLicenceError] = useState(false);

  const { mutate, isPending, isError, error } = useCreateCustomerMutation();

  const handleCustomerLicenceChange = (val: string) => {
    setShowCustomerLicenceError(!val);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    // prevent default form submission
    event.preventDefault();

    mutate(
      {
        customer: Object.fromEntries(
          new FormData(event.currentTarget)
        ) as unknown as CreateCustomerRequest
      },
      {
        onSuccess: () => {
          navigate(`/${URLS.ADMIN_CENTRE_CUSTOMERS}`);
        }
      }
    );
  };

  const handleCancel = () => {
    // redirect to list page
    navigate(`/${URLS.ADMIN_CENTRE_CUSTOMERS}`);
  };

  return (
    <AdminCentrePageWrapper section={ADMIN_SECTIONS.CUSTOMERS}>
      <FormPanel
        heading='New Customer'
        onSubmit={handleSubmit}
        onCancelClick={handleCancel}
        isSubmitting={isPending}
      >
        {isError && (
          <Callout.Root color='red' size='1' data-testid='customer-form-error'>
            <Callout.Icon>
              <Warning />
            </Callout.Icon>
            <Callout.Text>Unable to create customer.</Callout.Text>
            {error && <Callout.Text>{getMessageFromError(error)}</Callout.Text>}
          </Callout.Root>
        )}

        <FormField name='name' data-testid='customer-name-field'>
          <FormLabel>Name</FormLabel>
          <FormInput name='name' required disabled={isPending} />
          <FormMessage match='valueMissing'>This field is required</FormMessage>
        </FormField>

        <FormField name='tenantId' data-testid='customer-tenantId-field'>
          <FormLabel>Tenant ID</FormLabel>
          <FormInput name='tenantId' required disabled={isPending} />
          <FormMessage match='valueMissing'>This field is required</FormMessage>
        </FormField>

        <FormPrimitive.Field name='m365Licence' data-testid='customer-licence-field'>
          <FormLabel id='customer-licence-label'>M365 Licence</FormLabel>
          <FormRadioGroup
            aria-labelledby='customer-licence-label'
            name='m365Licence'
            required
            disabled={isPending}
            onInvalid={() => setShowCustomerLicenceError(true)}
            items={LICENCE_OPTIONS}
            onValueChange={handleCustomerLicenceChange}
          />
          <FormMessage match='valueMissing' forceMatch={showCustomerLicenceError}>
            This field is required
          </FormMessage>
        </FormPrimitive.Field>
      </FormPanel>
    </AdminCentrePageWrapper>
  );
};
