import { FunctionComponent, useState } from 'react';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Box, Button, Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('CustomerControlCard').create(() => ({
  container: {
    background: 'var(--white)',
    paddingBottom: '16px',
    borderBottom: '1px solid var(--slate-200)'
  },
  heading: {
    color: 'var(--slate-900)',
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 700
  },
  button: {
    cursor: 'pointer',
    padding: '16px 0',
    background: 'none',

    '&:hover, &:focus': {
      backgroundColor: 'transparent'
    }
  }
}));

type SidePanelSectionProps = {
  label: string;
  children?: React.ReactNode;
  onToggle?: (oldState: boolean, newState: boolean) => void;
};

export const SidePanelSection: FunctionComponent<SidePanelSectionProps> = ({
  label,
  children,
  onToggle
}) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen(!open);
    onToggle?.(open, !open);
  };

  return (
    <Box className={classes.container} data-testid='side-panel-section'>
      <Flex gap='3' direction='column'>
        <Button variant='soft' className={classes.button} onClick={handleToggle}>
          <Flex gap='2' justify='between' flexGrow='1' align='center'>
            <Text as='p' className={classes.heading}>
              {label}
            </Text>
            {open ? <CaretUp size={16} /> : <CaretDown size={16} />}
          </Flex>
        </Button>

        {open && <Box>{children}</Box>}
      </Flex>
    </Box>
  );
};
