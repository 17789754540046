import { FunctionComponent } from 'react';
import { RadioGroup } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('FormRadioGroup').create(() => ({
  item: {
    fontSize: '14px',
    lineHeight: 1.7,
    gap: '8px',
    alignItems: 'center'
  }
}));

export const FormRadioGroup: FunctionComponent<
  RadioGroup.RootProps & { items: { label: string; value: string }[] }
> = ({ items, ...props }) => {
  const { classes } = useStyles();

  return (
    <RadioGroup.Root {...props}>
      {items.map(({ label, value }, i) => (
        <RadioGroup.Item key={i} value={value} className={classes.item}>
          {label}
        </RadioGroup.Item>
      ))}
    </RadioGroup.Root>
  );
};
