export enum USER_ROLES {
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
  READER = 'READER'
}
export enum USER_ROLES_LABELS {
  ADMIN = 'Admin',
  OPERATOR = 'Operator',
  READER = 'Reader'
}

export enum USER_TYPES {
  CUSTOMER = 'CUSTOMER',
  THREATSCAPE = 'THREATSCAPE'
}
export enum USER_TYPES_LABELS {
  CUSTOMER = 'Customer',
  THREATSCAPE = 'Threatscape'
}

export enum USER_STATUS {
  REGISTERED = 'REGISTERED',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}
export enum USER_STATUS_LABELS {
  REGISTERED = 'Registered',
  ACTIVE = 'Active',
  DISABLED = 'Disabled'
}

export enum USER_AVATAR_COLOURS {
  TOMATO = 'tomato',
  RED = 'red',
  RUBY = 'ruby',
  CRIMSON = 'crimson',
  PINK = 'pink',
  PLUM = 'plum',
  PURPLE = 'purple',
  VIOLET = 'violet',
  IRIS = 'iris',
  INDIGO = 'indigo',
  BLUE = 'blue',
  CYAN = 'cyan',
  TEAL = 'teal',
  JADE = 'jade',
  GREEN = 'green',
  GRASS = 'grass',
  BRONZE = 'bronze',
  GOLD = 'gold',
  BROWN = 'brown',
  ORANGE = 'orange',
  AMBER = 'amber',
  YELLOW = 'yellow',
  LIME = 'lime',
  MINT = 'mint',
  SKY = 'sky'
}

export type SortOrder = 'asc' | 'desc';
export type SortBy = 'name' | 'customer.name' | 'status' | 'createdAt' | 'lastActivity' | 'role';

/**
 * Used in Create/Update Forms
 */
export const USER_TYPE_OPTIONS = [
  { label: USER_TYPES_LABELS.THREATSCAPE, value: USER_TYPES.THREATSCAPE },
  { label: USER_TYPES_LABELS.CUSTOMER, value: USER_TYPES.CUSTOMER }
];

export const USER_ROLE_OPTIONS = [
  { label: USER_ROLES_LABELS.READER, value: USER_ROLES.READER },
  { label: USER_ROLES_LABELS.OPERATOR, value: USER_ROLES.OPERATOR },
  { label: USER_ROLES_LABELS.ADMIN, value: USER_ROLES.ADMIN }
];

export const USER_STATUS_OPTIONS = [
  { label: USER_STATUS_LABELS.REGISTERED, value: USER_STATUS.REGISTERED },
  { label: USER_STATUS_LABELS.ACTIVE, value: USER_STATUS.ACTIVE },
  { label: USER_STATUS_LABELS.DISABLED, value: USER_STATUS.DISABLED }
];
