import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { tss } from 'tss-react';

import { NAVBAR_HEIGHT } from 'app/constants/app';
import { URLS } from 'app/constants/routes';
import logo from 'assets/logo.svg';

import { NavigationRightContent } from './NavigationRightContent';

const useStyles = tss.withName('NavigationMenu').create(() => ({
  root: {
    display: 'flex',
    height: `${NAVBAR_HEIGHT}px`,
    padding: '16px 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'var(--white)',
    position: 'relative',
    zIndex: 1,
    border: '4px solid',
    borderWidth: '2px',
    borderImageSlice: '1',
    borderImageSource: 'linear-gradient(to right, var(--brand-green), var(--brand-yellow))',
    borderLeft: '0',
    borderRight: '0',
    borderTop: '0',

    '&>div': {
      width: '100%'
    }
  },

  navList: {
    display: 'flex',
    padding: '8px 40px',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0
  },

  logo: {
    paddingRight: '32px'
  },

  navItem: {
    display: 'flex',
    gap: '10px',
    borderRadius: '6px',
    padding: '8px 16px',
    textDecoration: 'none',
    color: 'var(--slate-900)',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '20px',

    '&:focus, &:hover': {
      textDecoration: 'underline'
    },

    '&.active': {
      backgroundColor: 'var(--bg-default)'
    }
  }
}));

export const Navigation: FunctionComponent = () => {
  const { classes } = useStyles();

  return (
    <NavigationMenu.Root className={classes.root} data-testid='navigation'>
      <NavigationMenu.List className={classes.navList}>
        <NavigationMenu.Item>
          <NavigationMenu.Link asChild className={classes.logo}>
            <NavLink to='/'>
              <img src={logo} title='Overwatch' />
            </NavLink>
          </NavigationMenu.Link>
        </NavigationMenu.Item>
        <NavigationMenu.Item>
          <NavigationMenu.Link asChild>
            <NavLink to={URLS.DASHBOARDS} className={classes.navItem}>
              Dashboard
            </NavLink>
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link asChild>
            <NavLink to={URLS.CONTROLS} className={classes.navItem}>
              Controls
            </NavLink>
          </NavigationMenu.Link>
        </NavigationMenu.Item>
      </NavigationMenu.List>

      <NavigationRightContent />
    </NavigationMenu.Root>
  );
};
